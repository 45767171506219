import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, Subject, BehaviorSubject, forkJoin } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { jwtDecode } from "jwt-decode";
/* import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3'; */

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	get_masters_api_url() {
		let url: any = localStorage.getItem("masters_api_url");
		return url + "/api/";
	}

	get_mailbox_api_url() {
		let url: any = localStorage.getItem("mailbox_api_url");
		return url + "/api/";
	}

	get_users_api_url() {
		let url: any = localStorage.getItem("users_api_url");
		return url + "/api/";
	}

	get_case_api_url() {
		let url: any = localStorage.getItem("case_api_url");
		return url + "/api/";
	}

	get_activity_logs_api_url() {
		let url: any = localStorage.getItem("activity_logs_api_url");
		return url + "/api/";
	}

	get_auth_api_url() {
		let url: any = localStorage.getItem("auth_api_url");
		return url + "/api/";
	}

	get_login_api_url() {
		return environment.USER_API_URL + "login";
	}

	get_refresh_token_api_url() {
		return environment.USER_API_URL + "refresh";
	}

	get_user_api_url() {
		return environment.USER_API_URL + "api/";
	}

	get_file_upload_api_url() {
		let url: any = localStorage.getItem("file_upload_api_url");
		return url + "/api/";
	}

	private mailCountChange = new Subject<any>();
	private mailListRefresh = new Subject<any>();
	private mailOnlyCountChange = new Subject<any>();
	private notifiViewChange = new Subject<any>();
	private sessionExpMsg = new Subject<any>();
	private mailCount = new BehaviorSubject({ "unreadCount": 0, "unlinkCount": 0 });
	getMailCount = this.mailCount.asObservable();
	setMailCount(count: any) {
		this.mailCount.next(count);
	}

	gettingRefreshToken = false;

	constructor(private httpClient: HttpClient, private router: Router) { }

	getHeaders1() {
		return new HttpHeaders().append("Content-Type", 'application/json').append("Accept", 'application/json');
	}

	getHeaders2() {
		return new HttpHeaders().append("Content-Type", 'application/json').append("Accept", 'application/json');
	}

	getHeaders4() {
		return new HttpHeaders().append("Content-Type", 'application/json');
	}

	getHeaders5() {
		return new HttpHeaders().append("Accept", 'application/json');
	}

	sendMailListRefreshEvent() {
		this.mailListRefresh.next(null);
	}
	getMailListRefreshEvent(): Observable<any> {
		return this.mailListRefresh.asObservable();
	}

	sendCountChangeEvent() {
		this.mailCountChange.next(null);
	}
	getCountChangeEvent(): Observable<any> {
		return this.mailCountChange.asObservable();
	}

	sendOnlyCountChangeEvent() {
		this.mailOnlyCountChange.next(null);
	}
	getOnlyCountChangeEvent(): Observable<any> {
		return this.mailOnlyCountChange.asObservable();
	}

	sendNotiViewEvent() {
		this.notifiViewChange.next(null);
	}
	getNotiViewEvent(): Observable<any> {
		return this.notifiViewChange.asObservable();
	}

	sendSessionExpMessage() {
		this.sessionExpMsg.next(null);
	}
	getSessionExpMessage(): Observable<any> {
		return this.sessionExpMsg.asObservable();
	}

	public loginAPI(user: any) {
		var data = { email: user.email, password: user.password }
		return this.httpClient.post(this.get_login_api_url(), data);
	}

	public logoutUser() {
		localStorage.clear();
		localStorage.setItem("sessionexpire", "true");
		this.sendSessionExpMessage();
		this.router.navigate(['/login']);
	}

	public generate_qr(data: any) {
		return this.httpClient.post(this.get_auth_api_url() + "api/generate_qr", data, { responseType: "blob" });
	}

	public verifyGoogleAuthOTP(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams) };
		return this.httpClient.get(this.get_auth_api_url() + "api/verify_otp", options);
	}

	public getRefreshToken() {
		let username: any = localStorage.getItem("userEmail");
		if (username) {
			var data = { username: username };
			if (!this.gettingRefreshToken) {
				this.gettingRefreshToken = true;
				this.httpClient.post(this.get_refresh_token_api_url(), data).subscribe((data: any) => {
					this.gettingRefreshToken = false;
					localStorage.setItem('tokenKey', data["AuthenticationResult"]["IdToken"]);
				}, (error) => {
					this.gettingRefreshToken = false;
				});
			}
		}
	}

	public checkTokenExpiry() {
		try {
			let token: any = localStorage.getItem('tokenKey');
			const decoded = jwtDecode(token);
			let ts: any = decoded.exp;
			let expiry: any = new Date(ts * 1000);
			let now: any = new Date();
			const diffTime = expiry - now;
			let hours = diffTime / 36e5;
			if (hours < 1) {
				this.getRefreshToken();
			}
		} catch (error) {
			this.getRefreshToken();
		}
	}

	public passwordReset(email: any) {
		var data = { email: email }
		return this.httpClient.post(this.get_auth_api_url() + "passwordReset", data);
	}

	public newPassword(data: any) {
		return this.httpClient.post(this.get_user_api_url() + "set_password", data);
	}

	public sendOTP(data: any) {
		return this.httpClient.post(this.get_user_api_url() + "forgot_password", data);
	}

	public verifyOTP(data: any) {
		return this.httpClient.post(this.get_user_api_url() + "verify_otp", data);
	}

	public getMailBoxCount() {
		return this.httpClient.get(this.get_mailbox_api_url() + "mailBoxList", { headers: this.getHeaders1() });
	}

	public listMailFolders(page: any, limit: any, search: any) {
		const params: any = { page: page, limit: limit, search: search };
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_mailbox_api_url() + "listMailFolders", options);
	}

	/* public fetchfolderCount(folderName:any) {
		const params: any = { folderName: folderName };
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_mailbox_api_url() + "fetchfolderCount", options);
	} */

	public fetchLocalEmails(category: any, page: any, limit: any, search: any) {
		const params: any = { page: page, limit: limit, category: category, search: search };
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_mailbox_api_url() + "fetchLocalEmails", options);
	}

	public assignFlagToEvent(eventId: any, flag: any) {
		var data = { eventId: eventId, flag: flag }
		return this.httpClient.post(this.get_case_api_url() + "assignEventFlag", data, { headers: this.getHeaders1() });
	}

	public moveMailListtoFolder(mIdList: any, id: any) {
		var data = { mailIdList: mIdList, mailId: null, id: id }
		return this.httpClient.post(this.get_mailbox_api_url() + "moveMailtoFolder", data, { headers: this.getHeaders1() });
	}

	public moveMailListtoInbox(mIdList: any) {
		var data = { mailIdList: mIdList, mailId: null }
		return this.httpClient.post(this.get_mailbox_api_url() + "moveMailtoInbox", data, { headers: this.getHeaders1() });
	}

	public markListSpam(mId: any) {
		var data = { mailIdList: mId, mailId: null }
		return this.httpClient.post(this.get_mailbox_api_url() + "assignAsSpam", data, { headers: this.getHeaders1() });
	}

	public mailStatusSet(mId: any) {
		var data = { mailId: mId }
		return this.httpClient.post(this.get_mailbox_api_url() + "mailStatusSet", data, { headers: this.getHeaders1() });
	}

	public fetchReplyEmails(emailtext: any) {
		const params: any = { emailtext: emailtext };
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_mailbox_api_url() + "fetchReplyEmails", options);
	}

	public saveAsDraft(data: any) {
		return this.httpClient.post(this.get_mailbox_api_url() + "saveAsDraft", data, { headers: this.getHeaders1() });
	}

	public composeMail(data: any) {
		return this.httpClient.post(this.get_mailbox_api_url() + "composeMail", data, { headers: this.getHeaders1() });
	}

	public updateDraft(data: any) {
		return this.httpClient.post(this.get_mailbox_api_url() + "updateDraft", data, { headers: this.getHeaders1() });
	}

	public sendDraft(data: any) {
		return this.httpClient.post(this.get_mailbox_api_url() + "sendDraft", data, { headers: this.getHeaders1() });
	}

	public deleteDraft(id: any) {
		var data = { id: id };
		return this.httpClient.post(this.get_mailbox_api_url() + "deleteDraft", data, { headers: this.getHeaders1() });
	}

	public mailReplyMessage(data: any) {
		return this.httpClient.post(this.get_mailbox_api_url() + "mailReplyMessage", data, { headers: this.getHeaders1() });
	}

	public findMailIdDetails(id: any) {
		const params: any = { id: id };
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_mailbox_api_url() + "findMailIdDetails", options);
	}

	public fetchVessels() {
		return this.httpClient.get(this.get_masters_api_url() + "fetchVessels", { headers: this.getHeaders1() });
	}

	public allEventCount() {
		return this.httpClient.get(this.get_case_api_url() + "allEventCount", { headers: this.getHeaders1() });
	}

	public all_triage_code_count() {
		return this.httpClient.get(this.get_case_api_url() + "all_triage_code_count", { headers: this.getHeaders1() });
	}

	public mark_mail_importent(mailId: any, important: boolean) {
		return this.httpClient.get(this.get_mailbox_api_url() + "mark_importent/" + mailId + "?flag=" + important, { headers: this.getHeaders1() });
	}

	public eventList(params: any, tags: any) {
		let quer = "";
		for (const [key, value] of Object.entries(params)) {
			quer += "&" + key + "=" + value;
		}
		try {
			for (let i = 0; i < tags.length; i++) {
				quer += "&tagnamelist=" + tags[i];
			}
		} catch (error) { }
		quer = quer.slice(1);
		return this.httpClient.get(this.get_case_api_url() + "eventList?" + quer, { headers: this.getHeaders1() });
		/* const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "eventList", options); */
	}

	public companyList(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "companyList", options);
	}


	public companyNameValidation(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "companyNameValidation", options);
	}

	public companyEmailExist(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "companyEmailExist", options);
	}

	public countryCodes() {
		return this.httpClient.get("./assets/files/countryCodes.json", { headers: this.getHeaders2() });
	}

	public medicineMasterListOld(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "medicineMasterList", options);
	}

	public medicineMasterList(params: any) {
		let quer = "?";
		quer += "page=" + params["page"] + "&";
		quer += "limit=" + params["limit"] + "&";
		quer += "search=" + params["search"];
		for (let i = 0; i < params["tagnamelist"].length; i++) {
			quer += "&tagnamelist=" + params["tagnamelist"][i];
		}
		return this.httpClient.get(this.get_case_api_url() + "medicineMasterList" + quer, { headers: this.getHeaders1() });
	}

	public companyDetails(data: any) {
		return this.httpClient.post(this.get_masters_api_url() + "companyDetails", data, { headers: this.getHeaders1() });
	}

	public getCompanyDetails(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "companyDetails", options);
	}

	public updateCompany(data: any) {
		return this.httpClient.put(this.get_masters_api_url() + "updateCompany", data, { headers: this.getHeaders1() });
	}

	public removeCompany(companyId: any) {
		return this.httpClient.delete(this.get_masters_api_url() + "removeCompany/" + companyId, { headers: this.getHeaders1() });
	}

	public amoscodeList(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "amoscodeList", options);
	}


	public vesselList(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "vesselList", options);
	}

	public removeVessel(vesselId: any) {
		return this.httpClient.delete(this.get_masters_api_url() + "removeVessel?vesselId=" + vesselId, { headers: this.getHeaders1() });
	}

	public vesselNote(note: any, vesselId: any) {
		var data = { "note": note, "vesselId": vesselId }
		return this.httpClient.post(this.get_masters_api_url() + "vesselNote", data, { headers: this.getHeaders1() });
	}

	public resetPassword(password: any, vesselId: any, vesselUserId: any) {
		var data = { "password": password, "vesselId": vesselId, vesselUserId: vesselUserId };
		return this.httpClient.post(this.get_masters_api_url() + "resetPassword", data, { headers: this.getHeaders1() });
	}

	public vesselEmailExist(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "vesselEmailExist", options);
	}

	public imoNoValidation(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "imoNoValidation", options);
	}

	public pointOfContact(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "pointOfContact", options);
	}

	public pointOfContactAll() {
		return this.httpClient.get(this.get_masters_api_url() + "pointOfContactAll", { headers: this.getHeaders1() });
	}

	public flagstateList() {
		return this.httpClient.get(this.get_masters_api_url() + "flagstateList", { headers: this.getHeaders1() });
	}

	public vesselDetails(data: any) {
		return this.httpClient.post(this.get_masters_api_url() + "vesselDetails", data, { headers: this.getHeaders1() });
	}

	public updateVessel(data: any) {
		return this.httpClient.put(this.get_masters_api_url() + "updateVessel", data, { headers: this.getHeaders1() });
	}

	public getVesselDetails(vesselId: any) {
		return this.httpClient.get(this.get_masters_api_url() + "vesselDetails/" + vesselId, { headers: this.getHeaders1() });
	}

	public assignInventoryList(data: any) {
		return this.httpClient.post(this.get_masters_api_url() + "assignInventoryList", data, { headers: this.getHeaders1() });
	}

	public listVesselCrews(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "listVesselCrews", options);
	}

	public removeSeafarerDetails(seafarerId: any) {
		var data = { seafarerId: seafarerId };
		return this.httpClient.post(this.get_masters_api_url() + "removeSeafarerDetails", data, { headers: this.getHeaders1() });
	}

	public rankList() {
		return this.httpClient.get(this.get_masters_api_url() + "rankList", { headers: this.getHeaders1() });
	}

	public candidateRankList() {
		return this.httpClient.get(this.get_masters_api_url() + "candidateRankList", { headers: this.getHeaders1() });
	}

	public identityDocumentList() {
		return this.httpClient.get(this.get_masters_api_url() + "identityDocumentList", { headers: this.getHeaders1() });
	}

	public addSeafarerDetails(data: any) {
		return this.httpClient.post(this.get_masters_api_url() + "addSeafarerDetails", data, { headers: this.getHeaders1() });
	}

	public seafarerBasicDetails(seafarerId: any) {
		var params = { seafarerId: seafarerId };
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "seafarerBasicDetails", options);
	}

	public editSeafarerDetails(data: any) {
		return this.httpClient.put(this.get_masters_api_url() + "editSeafarerDetails", data, { headers: this.getHeaders1() });
	}

	public fetchVesselFromImono(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "fetchVesselFromImono", options);
	}

	public portList(search: any) {
		var params = { search: search };
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "portList", options);
	}

	public fetchCandidateFromCcid(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "fetchCandidateFromCcid", options);
	}

	public symptomQuestions(data: any) {
		return this.httpClient.post(this.get_case_api_url() + "symptomQuestions", data, { headers: this.getHeaders1() });
	}

	public createCustomEvent(data: any) {
		return this.httpClient.post(this.get_case_api_url() + "createCustomEvent", data, { headers: this.getHeaders1() });
	}

	public eventDetails(eventId: any) {
		var params = { eventId: eventId };
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "eventDetails", options);
	}

	public userList(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_users_api_url() + "listDoctors", options);
	}

	public caseDoctorAssign(data: any) {
		return this.httpClient.post(this.get_case_api_url() + "caseDoctorAssign", data, { headers: this.getHeaders1() });
	}

	public changeEventstatus(data: any) {
		return this.httpClient.post(this.get_case_api_url() + "changeEventstatus", data, { headers: this.getHeaders1() });
	}

	public editEventDetails(data: any) {
		return this.httpClient.post(this.get_case_api_url() + "editEventDetails", data, { headers: this.getHeaders1() });
	}

	public saveEventnotes(data: any) {
		return this.httpClient.post(this.get_case_api_url() + "saveEventnotes", data, { headers: this.getHeaders1() });
	}

	public IcdcodeList() {
		return this.httpClient.get(this.get_case_api_url() + "IcdcodeList", { headers: this.getHeaders1() });
	}

	public fetch_coordinates(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "fetch_coordinates", options);
	}

	public humanAnatomyAll() {
		/* return this.httpClient.get("./assets/vendor/human-anatomy/anatomy-ml-fl-ft-bk/all-points.json"); */
		return this.httpClient.get(this.get_case_api_url() + "fetch_coordinates_all", { headers: this.getHeaders1() });
	}

	public humanAnatomyAPIs(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams) };
		let responses = [
			this.httpClient.get(this.get_case_api_url() + "fetch_coordinates", options),
			/* this.httpClient.get("./assets/vendor/human-anatomy/anatomy-ml-fl-ft-bk/all-points.json") */
			this.httpClient.get(this.get_case_api_url() + "fetch_coordinates_all")
		];
		return forkJoin(responses);
	}

	public update_coordinates(params: any) {
		return this.httpClient.post(this.get_case_api_url() + "update_coordinates", params, { headers: this.getHeaders1() });
	}

	public eventMailList(params: any, tags: any) {
		let quer = "";
		for (const [key, value] of Object.entries(params)) {
			quer += "&" + key + "=" + value;
		}
		try {
			for (let i = 0; i < tags.length; i++) {
				quer += "&tags=" + tags[i];
			}
		} catch (error) { }
		quer = quer.slice(1);
		return this.httpClient.get(this.get_mailbox_api_url() + "eventMailList?" + quer, { headers: this.getHeaders1() });
		/* const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_mailbox_api_url() + "eventMailList", options); */
	}

	public sendEventDraft(data: any) {
		return this.httpClient.post(this.get_mailbox_api_url() + "sendEventDraft", data, { headers: this.getHeaders1() });
	}

	public mailUnlink(data: any) {
		return this.httpClient.post(this.get_mailbox_api_url() + "mailUnlink", data, { headers: this.getHeaders1() });
	}

	public eventFileList(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_mailbox_api_url() + "eventFileList", options);
	}

	public caseReportList(params: any) {
		return this.httpClient.get(this.get_case_api_url() + "caseReportList/" + params.eventId, { headers: this.getHeaders1() });
	}

	public caseFilesSave(data: any) {
		return this.httpClient.post(this.get_case_api_url() + "caseFilesSave", data, { headers: this.getHeaders1() });
	}

	public caseFilesDelete(data: any) {
		return this.httpClient.post(this.get_case_api_url() + "caseFilesDelete", data, { headers: this.getHeaders1() });
	}

	public saveCaseData(data: any) {
		return this.httpClient.post(this.get_case_api_url() + "saveCaseData", data, { headers: this.getHeaders1() });
	}

	/* public exportCases(data:any) {
		return this.httpClient.post(this.get_case_api_url() + "exportCases", data, { headers: this.getHeaders4(), observe: 'response', responseType: 'arraybuffer' });
	} */

	public eventInVessel(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "eventInVessel", options);
	}

	public getCandidateAndVesselDetails(crewId: any) {
		return this.httpClient.get(this.get_case_api_url() + "getCandidateAndVesseldetl/" + crewId, { headers: this.getHeaders1() });
	}

	public eventFromMailRequest(data: any) {
		return this.httpClient.post(this.get_case_api_url() + "eventFromMailRequest", data, { headers: this.getHeaders4(), observe: 'response', responseType: 'arraybuffer' });
	}

	public userProfileList(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_users_api_url() + "usertypeList", options);
	}

	public isUserEmailExist(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_users_api_url() + "userEmailExist", options);
	}

	public addUser(data: any) {
		return this.httpClient.post(this.get_user_api_url() + "addDoctor", data, { headers: this.getHeaders1() });
	}

	public updateUser(data: any) {
		return this.httpClient.post(this.get_user_api_url() + "editDoctorDetails", data, { headers: this.getHeaders1() });
	}

	public removeDoctor(doc: any) {
		var data = {
			doctorId: doc
		}
		return this.httpClient.post(this.get_user_api_url() + "removeDoctor", data, { headers: this.getHeaders1() });
	}

	public resetUserPassword(password: any, userId: any) {
		var data = { "password": password, "userId": userId }
		return this.httpClient.post(this.get_user_api_url() + "newUserPassword", data, { headers: this.getHeaders1() });
	}

	public accessRightList(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_users_api_url() + "profileList", options);
	}

	public removeAccess(pro: any) {
		var data = {
			profileId: pro
		}
		return this.httpClient.post(this.get_users_api_url() + "removeProfile", data, { headers: this.getHeaders1() });
	}

	public isProfileExist(params: any) {
		if (!params["profileId"]) {
			delete params["profileId"];
		}
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_users_api_url() + "validateProfileName", options);
	}

	public addAccess(data: any) {
		return this.httpClient.post(this.get_users_api_url() + "createProfile", data, { headers: this.getHeaders1() });
	}

	public updateProfile(data: any) {
		return this.httpClient.post(this.get_users_api_url() + "editProfile", data, { headers: this.getHeaders1() });
	}

	public getProfileDetails(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_users_api_url() + "profileDetails", options);
	}

	public pointofcontactList(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "pointOfContact", options);
	}

	public addPOC(data: any) {
		return this.httpClient.post(this.get_masters_api_url() + "pointOfContact", data, { headers: this.getHeaders1() });
	}

	public getPOCDetails(id: any) {
		const httpParams: HttpParamsOptions = { fromObject: id } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "pointOfContact/" + id, options);
	}

	public updatePOC(data: any, id: any) {
		return this.httpClient.put(this.get_masters_api_url() + "pointOfContact/" + id, data, { headers: this.getHeaders1() });
	}

	public removePOC(id: any) {
		return this.httpClient.delete(this.get_masters_api_url() + "pointOfContact/" + id, { headers: this.getHeaders1() });
	}

	public getVesselCheckList(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "certificateAnswersList", options);
	}

	public updateSummery(data: any) {
		return this.httpClient.post(this.get_masters_api_url() + "saveSummaryData", data, { headers: this.getHeaders1() });
	}

	/* public deleteFolderDetails(folderId:any) {
		var data = { folderId: folderId };
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.delete(this.get_masters_api_url() + "deleteFolderDetails", options);
	}
	
	public checkFolderExist(data:any) {
		return this.httpClient.post(this.get_masters_api_url() + "checkFolderExist", data, { headers: this.getHeaders1() });
	}
	
	public addFolderDetails(data:any) {
		return this.httpClient.post(this.get_masters_api_url() + "addFolderDetails", data, { headers: this.getHeaders1() });
	}
	
	public editFolderDetails(data:any) {
		return this.httpClient.post(this.get_masters_api_url() + "editFolderDetails", data, { headers: this.getHeaders1() });
	}
	
	public fetchfolderData(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "fetchfolderData", options);
	} */

	public caseFollowUpList(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "caseFollowUpList", options);
	}

	public getUserDetails(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_users_api_url() + "doctorDetails", options);
	}

	private newUnlinkedMailCount = new Subject<any>();

	sendUnlinkCountIncrementEvent() {
		this.newUnlinkedMailCount.next(null);
	}
	getUnlinkCountIncrementEvent(): Observable<any> {
		return this.newUnlinkedMailCount.asObservable();
	}

	private newUnreadMailCount = new Subject<any>();

	sendUnreadCountIncrementEvent() {
		this.newUnreadMailCount.next(null);
	}

	getUnreadCountIncrementEvent(): Observable<any> {
		return this.newUnreadMailCount.asObservable();
	}

	public addCaseMeds(event: any, medicines: any) {
		var data = { eventId: event, meds: medicines }
		return this.httpClient.post(this.get_case_api_url() + "saveCaseMedicines", data, { headers: this.getHeaders1() });
	}

	/* public addCaseVitals(event:any, vitals:any) {
		var data = { eventId: event, caseVitals: vitals }
		return this.httpClient.post(this.get_case_api_url() + "saveCaseVitals", data, { headers: this.getHeaders1() });
	} */

	public deleteMasterMed(id: any) {
		return this.httpClient.delete(this.get_case_api_url() + "medicineDetails/" + id, { headers: this.getHeaders1() });
	}

	public deleteSingleMed(id: any) {
		return this.httpClient.delete(this.get_case_api_url() + "medicineDetails/" + id, { headers: this.getHeaders1() });
	}

	public medicineNameValidation(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "validateActiveIngredient", options);
	}

	public getMedicineDetails(id: any) {
		return this.httpClient.get(this.get_case_api_url() + "medicineDetails/" + id, { headers: this.getHeaders1() });
	}

	public createUpdateMedicines(data: any, id: any) {
		if (id == null) {
			return this.httpClient.post(this.get_case_api_url() + "medicineDetails", data, { headers: this.getHeaders1() });
		} else {
			return this.httpClient.put(this.get_case_api_url() + "medicineDetails", data, { headers: this.getHeaders1() });
		}
	}

	public loadQuestionMasters() {
		return this.httpClient.get(this.get_case_api_url() + "modules", { headers: this.getHeaders1() });
	}

	public removeQuestionModule(id: any) {
		return this.httpClient.delete(this.get_case_api_url() + "modules/" + id, { headers: this.getHeaders1() });
	}

	public addQuestionModule(data: any) {
		return this.httpClient.post(this.get_case_api_url() + "modules", data, { headers: this.getHeaders1() });
	}

	public updateQuestionModule(data: any) {
		return this.httpClient.put(this.get_case_api_url() + "modules/" + data.id, data, { headers: this.getHeaders1() });
	}

	public loadQuestionGroups(id: any) {
		return this.httpClient.get(this.get_case_api_url() + "groups/" + id, { headers: this.getHeaders1() });
	}

	public loadQuestions(id: any) {
		return this.httpClient.get(this.get_case_api_url() + "questions/" + id, { headers: this.getHeaders1() });
	}

	public addNewGroup(data: any) {
		return this.httpClient.post(this.get_case_api_url() + "groups", data, { headers: this.getHeaders1() });
	}

	public deleteGroup(id: any) {
		return this.httpClient.delete(this.get_case_api_url() + "groups/" + id, { headers: this.getHeaders1() });
	}

	public AddModuleNewQuestion(data: any) {
		return this.httpClient.post(this.get_case_api_url() + "questions", data, { headers: this.getHeaders1() });
	}

	public UpdateModuleQuestion(data: any) {
		return this.httpClient.put(this.get_case_api_url() + "questions", data, { headers: this.getHeaders1() });
	}

	public removeQuestion(id: any) {
		return this.httpClient.delete(this.get_case_api_url() + "questions/" + id, { headers: this.getHeaders1() });
	}

	private unselectBranch = new Subject<any>();
	sendUnselectBranchEvent() {
		this.unselectBranch.next(null);
	}
	getUnselectBranchEvent(): Observable<any> {
		return this.unselectBranch.asObservable();
	}

	public addCaseQuestions(eventId: any, questions: any) {
		var data = { eventId: eventId, questionAnswers: questions }
		return this.httpClient.post(this.get_masters_api_url() + "saveCaseQnAnswers", data, { headers: this.getHeaders1() });
	}

	private followUpRefresh = new Subject<any>();

	sendFollowUpRefreshEvent() {
		this.followUpRefresh.next(null);
	}

	getFollowUpRefreshEvent(): Observable<any> {
		return this.followUpRefresh.asObservable();
	}

	/* public saveQnAnsData(data:any) {
		return this.httpClient.post(this.get_case_api_url() + "saveCaseQnAnsDetails", data, { headers: this.getHeaders1() });
	} */

	/* public appointmentList(data:any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "appointmentList", options);
	} */

	/* public clipprofileList(data:any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "clipprofileList", options);
	} */

	/* public clipDoctorList(data:any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "clipDoctorList", options);
	} */

	/* public clipClinicList(data:any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "clipClinicList", options);
	} */

	/* public purposeList(data:any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "purposeList", options);
	} */

	/* public departmentList() {
		return this.httpClient.get(this.get_case_api_url() + "departmentList", { headers: this.getHeaders1() });
	} */

	/* public subdepartmentList() {
		return this.httpClient.get(this.get_case_api_url() + "subdepartmentList", { headers: this.getHeaders1() });
	} */

	/* public createAppointment(data:any) {
		return this.httpClient.post(this.get_case_api_url() + "createAppointment", data, { headers: this.getHeaders1() });
	} */

	/* public appointmentDetails(data:any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "appointmentDetails", options);
	} */

	/* public updateAppointment(data:any) {
		return this.httpClient.post(this.get_case_api_url() + "updateAppointment", data, { headers: this.getHeaders1() });
	} */

	/* public removeAppointment(data:any) {
		return this.httpClient.post(this.get_case_api_url() + "removeAppointment", data, { headers: this.getHeaders1() });
	} */

	/* public candidateReportList(data:any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "candidateReportList", options);
	} */

	/* public candidateActivityDetails(data:any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "candidateActivityDetails", options);
	} */

	/* public candidateReport(data:any) {
		return this.httpClient.post(this.get_case_api_url() + "candidateReport", data, { headers: this.getHeaders1() });
	} */

	/* public rebookAppointment(data:any) {
		return this.httpClient.post(this.get_case_api_url() + "rebookAppointment", data, { headers: this.getHeaders1() });
	} */

	public listSeafarer(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "seafarer", options);
	}

	public getVesselList(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "fetchCompVessels", options);
	}

	public addSeafarer(data: any) {
		return this.httpClient.post(this.get_masters_api_url() + "seafarer", data, { headers: this.getHeaders1() });
	}

	public getSeafarer(id: any) {
		const httpParams: HttpParamsOptions = { fromObject: id } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "seafarer/" + id, options);
	}

	public updateSeafarer(data: any, id: any) {
		return this.httpClient.put(this.get_masters_api_url() + "seafarer", data, { headers: this.getHeaders1() });
	}

	public removeSeafarer(id: any) {
		return this.httpClient.delete(this.get_masters_api_url() + "seafarer/" + id, { headers: this.getHeaders1() });
	}

	public unlinkSeafarer(seafarerId: any, type: any) {
		var data = { seafarerId: seafarerId, type: type };

		return this.httpClient.post(this.get_masters_api_url() + "unlinkSeafarer", data, { headers: this.getHeaders1() });
	}

	public getPOCEmails(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_mailbox_api_url() + "getPOCEmails", options);
	}

	/* public vesselsMedicine(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "vesselInventoryDetails", options);
	} */

	/* public AddMedicineBatch(data:any) {
		return this.httpClient.post(this.get_masters_api_url() + "AddMedicineBatch", data, { headers: this.getHeaders1() });
	} */

	/* public DisplayBatchList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "DisplayBatchList", options);
	} */

	/* public updateMedicine(data:any) {
		return this.httpClient.post(this.get_masters_api_url() + "addPrescription", data, { headers: this.getHeaders1() });
	} */

	/* public assignInventoryInVessel(data:any) {
		return this.httpClient.post(this.get_masters_api_url() + "assignInventoryInVessel", data, { headers: this.getHeaders1() });
	} */

	/* public getVesselMedicines(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "medicinesVessel", options);
	} */

	/* public updateMinQty(data:any) {
		return this.httpClient.post(this.get_masters_api_url() + "updateMedMinQty", data, { headers: this.getHeaders1() });
	} */

	public getMailDetails(params: any) {
		/* const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_mailbox_api_url() + "fetchLocalSingleEmail", options); */
		return this.httpClient.get(this.get_mailbox_api_url() + "fetchLocalSingleEmail/" + params["id"], { headers: this.getHeaders1() });
	}

	public getAgoraToken(channelName: any, userId: any) {
		let data = {
			channelName: channelName,
			userId: userId
		}
		return this.httpClient.post(this.get_user_api_url() + "agora/token", data, { headers: this.getHeaders1() });
	}

	public getAgoraTokenOpen(channelName: any, userId: any) {
		let data = {
			channelName: channelName,
			userId: userId
		}
		return this.httpClient.post(this.get_user_api_url() + "agora/token", data, { headers: this.getHeaders1() });
	}

	public userLogOut() {
		var data = {};
		return this.httpClient.post(this.get_user_api_url() + "logout", data);
	}

	/* getPdf(details: any) {
		var data = details;
		return this.httpClient.post(this.get_case_api_url() + "generatePrescriptionReport", data, { headers: this.getHeaders4(), observe: 'response', responseType: 'blob' as 'json' });
	} */

	getPdf(details: any) {
		var data = details;
		return this.httpClient.post(this.get_case_api_url() + "generatePrescriptionReport", data);
	}

	public casePrescriptionList(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "casePrescriptionList", options);
	}

	public doneFollowUp(data: any) {
		return this.httpClient.get(this.get_masters_api_url() + "doneFollowUp/" + data.id, { headers: this.getHeaders1() });
	}

	/* public deleteVesselMedicines(data:any) {
		return this.httpClient.post(this.get_masters_api_url() + "removeMedicineInVessel", data, { headers: this.getHeaders1() });
	} */

	public userApptHP(params: any) {
		return this.httpClient.post(this.get_case_api_url() + "userApptHP", params, { headers: this.getHeaders1() });
	}

	/* public getSeafarerProfile(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "userHPDetails", options);
	} */

	public markAllMailsReplied(params: any) {
		return this.httpClient.post(this.get_mailbox_api_url() + "makeAllMailReplied", params, { headers: this.getHeaders1() });
	}

	/* public hpRegistrationList(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "healthPassportRegList", options);
	} */

	/* public getCaseSpecialist(params:any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "caseSpecialistList", options);
	} */

	/* public getGroups() {
		return this.httpClient.get(this.get_case_api_url() + "getGroups", { headers: this.getHeaders1() });
	} */

	/* public addWellnessAppt(params:any) {
		return this.httpClient.post(this.get_case_api_url() + "addWellnessAppt", params, { headers: this.getHeaders1() });
	} */

	/* public getWellnessAppt(seafarer_id:any) {
		var params = { seafarer_id: seafarer_id }
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "getWellnessAppt", options);
	} */

	public eventsData(params: any) {
		return this.httpClient.post(this.get_activity_logs_api_url() + "eventsData", params, { headers: this.getHeaders1() });
	}

	public postEmergencyNote(params: any) {
		return this.httpClient.post(this.get_case_api_url() + "emergency", params, { headers: this.getHeaders1() });
	}

	public postGlobalEmergencyNote(params: any) {
		return this.httpClient.post(this.get_case_api_url() + "emergency-global", params, { headers: this.getHeaders1() });
	}

	public getFlagStates(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "listFlagstates", options);
	}


	public getFlagStateMedicineList(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "flagstateMedicineList", options);
	}

	public mainCompanyList(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "company_master_list", options);
	}

	public addEditMainCompany(params: any, id: any) {
		if (id) {
			return this.httpClient.put(this.get_masters_api_url() + "company_master/" + id, params, { headers: this.getHeaders1() });
		} else {
			return this.httpClient.post(this.get_masters_api_url() + "company_master", params, { headers: this.getHeaders1() });
		}
	}

	public removeMainCompany(id: any) {
		return this.httpClient.delete(this.get_masters_api_url() + "company_master/" + id, { headers: this.getHeaders1() });
	}

	public getMainCompanyDetails(id: any) {
		return this.httpClient.get(this.get_masters_api_url() + "company_master/" + id, { headers: this.getHeaders1() });
	}

	public company_user_list(params: any, compId: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "company_user_list/" + compId, options);
	}

	public removeMainCompanyUser(id: any) {
		return this.httpClient.delete(this.get_masters_api_url() + "company_user/" + id, { headers: this.getHeaders1() });
	}

	public addEditMainCompanyUser(params: any, id: any) {
		if (id) {
			return this.httpClient.put(this.get_masters_api_url() + "company_user/" + id, params, { headers: this.getHeaders1() });
		} else {
			return this.httpClient.post(this.get_masters_api_url() + "company_user", params, { headers: this.getHeaders1() });
		}
	}

	public getMainCompanyUserDetails(id: any) {
		return this.httpClient.get(this.get_masters_api_url() + "company_user/" + id, { headers: this.getHeaders1() });
	}

	public fleetListByCompany(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "fleetList", options);
	}

	public updateCaseSymptoms(params: any) {
		return this.httpClient.post(this.get_case_api_url() + "symptomUpdate", params, { headers: this.getHeaders1() });
	}

	/* public updateFlagstateMedicine(data:any) {
		return this.httpClient.post(this.get_masters_api_url() + "updateFlagstateMedicine", data, { headers: this.getHeaders1() });
	} */

	/* public getCompanyCommunicationEvent(params:any) {
		return this.httpClient.get(this.get_case_api_url() + "companyCommunication/" + params, { headers: this.getHeaders1() });
	} */

	public fleet_user_list(params: any, fleetId: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "fleet_user_list/" + fleetId, options);
	}

	public removeFleetUser(id: any) {
		return this.httpClient.delete(this.get_masters_api_url() + "fleet_user/" + id, { headers: this.getHeaders1() });
	}

	public addEditFleetUser(params: any, id: any) {
		if (id) {
			return this.httpClient.put(this.get_masters_api_url() + "fleet_user/" + id, params, { headers: this.getHeaders1() });
		} else {
			return this.httpClient.post(this.get_masters_api_url() + "fleet_user", params, { headers: this.getHeaders1() });
		}
	}

	public getFleetUserDetails(id: any) {
		return this.httpClient.get(this.get_masters_api_url() + "fleet_user/" + id, { headers: this.getHeaders1() });
	}

	public searchMail(data: any) {
		const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_mailbox_api_url() + "mail/searchMail", options);
	}

	/* public getEventVitals(id:any) {
		return this.httpClient.get(this.get_case_api_url() + "vitals/"+id, { headers: this.getHeaders1() });
	} */

	public get_default_text_editor_text() {
		return this.httpClient.get("./assets/files/default_text_editor_text.json");
	}

	public dropdown_options(id: any) {
		return this.httpClient.get(this.get_users_api_url() + "dropdown-options/" + id, { headers: this.getHeaders1() });
	}

	public add_analytics(data: any) {
		return this.httpClient.post(this.get_case_api_url() + "add-analytics", data, { headers: this.getHeaders1() });
	}

	public getCrewStatus(id: any) {
		const httpParams: HttpParamsOptions = { fromObject: { EventId: id } } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "crew-status", options);
	}

	public getCrewWorkStatus(id: any) {
		const httpParams: HttpParamsOptions = { fromObject: { EventId: id } } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "crew-work-status", options);
	}

	public getTreatmentAdvised(id: any) {
		return this.httpClient.get(this.get_case_api_url() + "treatment-advised/" + id, { headers: this.getHeaders1() });
	}

	public getFollowUp(id: any) {
		const httpParams: HttpParamsOptions = { fromObject: { EventId: id } } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "follow-up", options);
	}

	public getNextFollowUp(id: any) {
		const httpParams: HttpParamsOptions = { fromObject: { EventId: id } } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "next-follow-up", options);
	}

	public getCommMode(id: any) {
		const httpParams: HttpParamsOptions = { fromObject: { EventId: id } } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "communication-mode", options);
	}

	public getSpecialistConsulted(id: any) {
		const httpParams: HttpParamsOptions = { fromObject: { EventId: id } } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "specialist-selected", options);
	}

	public getPrecautionAdvised(id: any) {
		const httpParams: HttpParamsOptions = { fromObject: { EventId: id } } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "precaution-advised", options);
	}

	public getReviewerNotes(id: any) {
		return this.httpClient.get(this.get_case_api_url() + "reviewers-notes/" + id, { headers: this.getHeaders1() });
	}

	public getCounsellingNotes(id: any) {
		return this.httpClient.get(this.get_case_api_url() + "get_counselling_by_event/" + id, { headers: this.getHeaders1() });
	}

	public getDoctorNotes(id: any) {
		const httpParams: HttpParamsOptions = { fromObject: { EventId: id } } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "doctor-notes", options);
	}

	/* public getSpecialistSelected(id:any) {
		return this.httpClient.get(this.get_case_api_url() + "specialist-selected/"+id, { headers: this.getHeaders1() });
	}
	
	public getCandidateSymptoms(id:any) {
		return this.httpClient.get(this.get_case_api_url() + "candidate-symptoms/"+id, { headers: this.getHeaders1() });
	}
	
	public getCandidateSymptomsHistory(id:any) {
		return this.httpClient.get(this.get_case_api_url() + "candidate-symptoms-history/"+id, { headers: this.getHeaders1() });
	}
	
	public addCandidateSymptoms(data:any) {
		return this.httpClient.post(this.get_case_api_url() + "candidate-symptoms", data, { headers: this.getHeaders1() });
	} */

	public getAllSymptoms(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "all-symptoms", options);
	}

	public doneReviewerNote(id: any) {
		return this.httpClient.post(this.get_case_api_url() + "confirm-reviewers-notes/" + id, { reviewers_notes_completed: true }, { headers: this.getHeaders1() });
	}

	public createUpdateCounsellingNote(data: any) {
		if (data.id) {
			return this.httpClient.put(this.get_case_api_url() + "edit_counselling_notes", data);
		} else {
			return this.httpClient.post(this.get_case_api_url() + "save_counselling", data);
		}
	}

	public deleteCounsellingNote(id: any) {
		return this.httpClient.delete(this.get_case_api_url() + "delete_counselling_notes/" + id);
	}

	public saveReport(data: any) {
		return this.httpClient.post(this.get_case_api_url() + "report", data, { headers: this.getHeaders1() });
	}

	public getReport(id: any) {
		const httpParams: HttpParamsOptions = { fromObject: { event_id: id } } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "report", options);
	}

	public saveReport1(data: any, eventId: any) {
		return this.httpClient.post(this.get_case_api_url() + "repatriation-report/" + eventId, data, { headers: this.getHeaders1() });
	}

	public getReport1(id: any) {
		return this.httpClient.get(this.get_case_api_url() + "repatriation-report/" + id, { headers: this.getHeaders1() });
	}

	public getAllSpecialists() {
		return this.httpClient.get(this.get_case_api_url() + "specialists", { headers: this.getHeaders1() });
	}

	public getAllHandovers() {
		return this.httpClient.get(this.get_case_api_url() + "handover", { headers: this.getHeaders1() });
	}

	public createHandover(data: any) {
		return this.httpClient.post(this.get_case_api_url() + "handover", data, { headers: this.getHeaders1() });
	}

	public updateHandover(data: any, id: any) {
		return this.httpClient.put(this.get_case_api_url() + "handover/" + id, data, { headers: this.getHeaders1() });
	}

	public deleteHandover(id: any) {
		return this.httpClient.delete(this.get_case_api_url() + "handover/" + id, { headers: this.getHeaders1() });
	}

	public sendHandover() {
		return this.httpClient.post(this.get_case_api_url() + "send-handover", {}, { headers: this.getHeaders1() });
	}

	public deleteAnalytics(id: any) {
		return this.httpClient.delete(this.get_case_api_url() + "analytics/" + id, { headers: this.getHeaders1() });
	}

	public mandatoryCheck(id: any) {
		return this.httpClient.get(this.get_case_api_url() + "mandatory-check/" + id, { headers: this.getHeaders1() });
	}

	public mandatoryCheckNew(data: any) {
		return this.httpClient.post(this.get_case_api_url() + "mandatory-check", data);
	}

	public getRating(id: any) {
		return this.httpClient.get(this.get_case_api_url() + "event-rating/" + id, { headers: this.getHeaders1() });
	}

	public setRating(id: any, rating: any) {
		return this.httpClient.post(this.get_case_api_url() + "event-rating/" + id, { rating: rating }, { headers: this.getHeaders1() });
	}

	public getTagSuggestions() {
		return this.httpClient.get(this.get_case_api_url() + "tags", { headers: this.getHeaders1() });
	}

	public fetchMedicineTags() {
		return this.httpClient.get(this.get_case_api_url() + "sub-symptom-tags", { headers: this.getHeaders1() });
	}

	public fetchEventMedicineTags(id: any) {
		return this.httpClient.get(this.get_case_api_url() + "event-sub-symptom-tags/" + id, { headers: this.getHeaders1() });
	}

	public saveEventMedicineTags(subSymptoms: any, eventId: any) {
		return this.httpClient.put(this.get_case_api_url() + "sub-symptoms/" + eventId, { sub_symptoms: subSymptoms }, { headers: this.getHeaders1() });
	}

	public getMailTagSuggestions() {
		return this.httpClient.get(this.get_case_api_url() + "mails/tags", { headers: this.getHeaders1() });
	}

	public getEventTags(EventId: any) {
		return this.httpClient.get(this.get_case_api_url() + "tags/" + EventId, { headers: this.getHeaders1() });
	}

	public getMailTags(mailId: any) {
		return this.httpClient.get(this.get_case_api_url() + "mails/tags/" + mailId, { headers: this.getHeaders1() });
	}

	public saveEventTags(id: any, tags: any) {
		return this.httpClient.post(this.get_case_api_url() + "tags/" + id, { tags: tags }, { headers: this.getHeaders1() });
	}

	public saveMailTags(id: any, tags: any) {
		return this.httpClient.post(this.get_case_api_url() + "mails/tags/" + id, { tags: tags }, { headers: this.getHeaders1() });
	}

	public markForClosure(id: any, value: any) {
		return this.httpClient.post(this.get_case_api_url() + "events/mark-for-closure/" + id + "/value/" + value, {}, { headers: this.getHeaders1() });
	}

	public getMultipleMailDetails(mailIds: any): Observable<any[]> {
		let responses: Observable<any>[] = [];
		for (let i = 0; i < mailIds.length; i++) {
			/* let params = {id:mailIds[i]};
			const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
			const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
			let response = this.httpClient.get(this.get_mailbox_api_url() + "fetchLocalSingleEmail", options); */
			let response = this.httpClient.get(this.get_mailbox_api_url() + "fetchLocalSingleEmail/" + mailIds[i], { headers: this.getHeaders1() });
			responses.push(response);
		}
		return forkJoin(responses);
	}

	public getTemplateList(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "template", options);
	}

	public deleteTemplate(id: any) {
		return this.httpClient.delete(this.get_case_api_url() + "template/" + id, { headers: this.getHeaders1() });
	}

	public getTemplateDetails(id: any) {
		return this.httpClient.get(this.get_case_api_url() + "template/" + id, { headers: this.getHeaders1() });
	}

	public createUpdateTemplate(data: any, id: any) {
		if (id) {
			return this.httpClient.put(this.get_case_api_url() + "template/" + id, data, { headers: this.getHeaders1() });
		} else {
			return this.httpClient.post(this.get_case_api_url() + "template", data, { headers: this.getHeaders1() });
		}
	}

	public getRankList(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "rank", options);
	}

	public deleteRank(id: any) {
		return this.httpClient.delete(this.get_masters_api_url() + "rank/" + id, { headers: this.getHeaders1() });
	}

	public deleteMultipleRanks(id: any) {
		return this.httpClient.delete(this.get_masters_api_url() + "rank/" + id, { headers: this.getHeaders1() });
	}

	public getRankDetails(id: any) {
		return this.httpClient.get(this.get_masters_api_url() + "rank/" + id, { headers: this.getHeaders1() });
	}

	public createUpdateRank(data: any, id: any) {
		if (id) {
			return this.httpClient.put(this.get_masters_api_url() + "rank/" + id, data, { headers: this.getHeaders1() });
		} else {
			return this.httpClient.post(this.get_masters_api_url() + "rank", data, { headers: this.getHeaders1() });
		}
	}

	public nationalityList(search: any) {
		var params = { search: search };
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "nationalityList", options);
	}

	public getNationalityList(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "nationality", options);
	}

	public deleteNationality(id: any) {
		return this.httpClient.delete(this.get_masters_api_url() + "nationality?id=" + id, { headers: this.getHeaders1() });
	}

	public deleteMultipleNationality(id: any) {
		return this.httpClient.delete(this.get_masters_api_url() + "nationality/" + id, { headers: this.getHeaders1() });
	}

	public getNationalityDetails(id: any) {
		return this.httpClient.get(this.get_masters_api_url() + "nationality/" + id, { headers: this.getHeaders1() });
	}

	public createUpdateNationality(data: any, id: any) {
		if (id) {
			return this.httpClient.put(this.get_masters_api_url() + "nationality/" + id, data, { headers: this.getHeaders1() });
		} else {
			return this.httpClient.post(this.get_masters_api_url() + "nationality", data, { headers: this.getHeaders1() });
		}
	}

	public getMedicineSuggestion(params: any) {
		let quer = "";
		if (params.length) {
			quer = "?";
		}
		for (let i = 0; i < params.length; i++) {
			if (i == 0) {
				quer += "tags=" + params[i];
			} else {
				quer += "&tags=" + params[i];
			}
		}
		return this.httpClient.get(this.get_case_api_url() + "medicine-suggestion" + quer, { headers: this.getHeaders1() });
	}

	public createUpdateShoreAssistance(data: any, id: any) {
		if (id) {
			return this.httpClient.put(this.get_case_api_url() + "shore-assistance/" + id, data, { headers: this.getHeaders1() });
		} else {
			return this.httpClient.post(this.get_case_api_url() + "shore-assistance", data, { headers: this.getHeaders1() });
		}
	}

	public getShoreAssistance(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "shore-assistance", options);
	}

	public deleteShoreAssistance(id: any) {
		return this.httpClient.delete(this.get_case_api_url() + "shore-assistance/" + id, { headers: this.getHeaders1() });
	}

	public getFollowupCount() {
		return this.httpClient.get(this.get_case_api_url() + "followup-count", { headers: this.getHeaders1() });
	}

	public getCountryList() {
		return this.httpClient.get(this.get_masters_api_url() + "country_list", { headers: this.getHeaders1() });
	}

	public getPortsByCountry(countryCode: any) {
		const httpParams: HttpParamsOptions = { fromObject: { Country_Initial: countryCode } } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "port_list", options);
	}

	public getCLIPFiles(eventId: any) {
		return this.httpClient.get(this.get_case_api_url() + "clip-files/" + eventId, { headers: this.getHeaders1() });
	}

	public signOffClip(data: any, id: any) {
		return this.httpClient.post(this.get_case_api_url() + "clip/sign-off/" + id, data, { headers: this.getHeaders1() });
	}

	public saveVesselInvFile(formData: any) {
		return this.httpClient.post(this.get_masters_api_url() + "inventoryFileUpload", formData);
	}

	public ownerList() {
		return this.httpClient.get(this.get_masters_api_url() + "allowner", { headers: this.getHeaders1() });
	}

	public getOwnerDetails(id: any) {
		const httpParams: HttpParamsOptions = { fromObject: { id: id } } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "owner/" + id);
	}

	public createUpdateOwner(data: any, id: any) {
		if (id) {
			return this.httpClient.put(this.get_masters_api_url() + "owner/" + id, data);
		} else {
			return this.httpClient.post(this.get_masters_api_url() + "createowner", data);
		}
	}

	public getOwnerList(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "owner", options);
	}

	public deleteOwner(id: any) {
		return this.httpClient.delete(this.get_masters_api_url() + "owner?id=" + id, { headers: this.getHeaders1() });
	}

	public getBillingList(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "invoice", options);
	}

	public deleteBilling(id: any) {
		return this.httpClient.delete(this.get_masters_api_url() + "invoice/" + id, { headers: this.getHeaders1() });
	}

	public getBillingDetails(id: any) {
		return this.httpClient.get(this.get_masters_api_url() + "invoice/" + id, { headers: this.getHeaders1() });
	}

	public createUpdateBilling(data: any) {
		if (data.id) {
			return this.httpClient.put(this.get_masters_api_url() + "invoice", data, { headers: this.getHeaders1() });
		} else {
			return this.httpClient.post(this.get_masters_api_url() + "invoice", data, { headers: this.getHeaders1() });
		}
	}

	public currencyList() {
		return this.httpClient.get("./assets/files/currencies.json");
	}

	public get_vesseldata_by_company(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_masters_api_url() + "get_vesseldata_by_company", options);
	}

	public paid_unpaid_invoice(data: any) {
		return this.httpClient.post(this.get_masters_api_url() + "paid_unpaid", data, { headers: this.getHeaders1() });
	}

	public key_text_suggestions() {
		return this.httpClient.get(this.get_case_api_url() + "key_text_suggestions");
	}

	public getBillPdf(data: any) {
		return this.httpClient.post(this.get_case_api_url() + "invoice_report", data, { observe: 'response', responseType: 'arraybuffer' });
	}

	public api_to_send_notification(eventId: any, type: string) {
		return this.httpClient.get(this.get_case_api_url() + "api_to_send_notification/" + eventId + "?flag=" + type);
	}

	public getCaseUpdates(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams) };
		return this.httpClient.get(this.get_case_api_url() + "case_status_list", options);
	}

	public qcList(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "qc_notes", options);
	}

	public teamsHandoverList(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "handover_notes_asper_dr", options);
	}

	public get_tata_tele_call_count() {
		return this.httpClient.get(this.get_case_api_url() + "get_tata_tele_call_count");
	}

	public get_tata_tele_unlinked_count() {
		return this.httpClient.get(this.get_case_api_url() + "get_tata_tele_unlinked_count");
	}

	public callList(params: any) {
		const httpParams: HttpParamsOptions = { fromObject: params } as HttpParamsOptions;
		const options = { params: new HttpParams(httpParams), headers: this.getHeaders1() };
		return this.httpClient.get(this.get_case_api_url() + "get_call_record", options);
	}

	public calls_event(data: any) {
		return this.httpClient.post(this.get_case_api_url() + "calls_event", data);
	}

	public event_calls(data: any) {
		return this.httpClient.post(this.get_case_api_url() + "event_calls", data);
	}

	public saveContact(data: any) {
		return this.httpClient.post(this.get_case_api_url() + "save_contact_to_vessel", data);
	}

	public unlinkVesselNumber(data: any) {
		return this.httpClient.post(this.get_case_api_url() + "remove_contact_from_vessel", data);
	}

	public getMailContent(url:any) {
		return this.httpClient.get(url,{responseType: "text"});
	}

	/* -----------------------File Upload APIs Start----------------------- */

	public fileUpload(filename: any, destination: string) {
		const formData = new FormData();
		formData.append('file', "");
		formData.append('filename', filename);
		formData.append('destination', destination);
		return this.httpClient.post(this.get_file_upload_api_url() + "fileUpload", formData);
	}

	public fileUploadMultiple(fileNames: string[], destination: string): Observable<any[]> {
		let responses: Observable<any>[] = [];
		for (let i = 0; i < fileNames.length; i++) {
			const formData = new FormData();
			formData.append('file', "");
			formData.append('filename', fileNames[i]);
			formData.append('destination', destination);
			let response = this.httpClient.post(this.get_file_upload_api_url() + "fileUpload", formData);
			responses.push(response);
		}
		return forkJoin(responses);
	}

	public uploadFileToS3(file: any, url: any) {
		return this.httpClient.put(url, file, { reportProgress: true });
	}
	/* -----------------------File Upload APIs End----------------------- */
}

export interface HttpParamsOptions {
	fromString?: string;
	fromObject?: {
		[param: string]: string | string[];
	};
	encoder?: HttpParameterCodec;
}

interface HttpParameterCodec {
	encodeKey(key: string): string
	encodeValue(value: string): string
	decodeKey(key: string): string
	decodeValue(value: string): string
}